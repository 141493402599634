import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
    calculateTotal,
    convertCartToLineItems,
    extractFeeItemsFromLineItems,
    FeeItem,
    LineItem,
} from '../../utils/calculations.ts';
import {
    TicketCategoryVisibility,
    type OrderSummaryEventQuery,
    TicketCategoryUnitPrice,
} from '../../graphql/graphql.ts';
import { addonIdsToKey, addonKeyToIds } from '../../utils/addon/index.ts';
import {
    A1CheckoutCartObject,
    A1CheckoutStorageObject,
    CommonEventObject,
    ICartContext,
} from '../../types/common.ts';
import sha256 from 'crypto-js/sha256';
import * as Sentry from '@sentry/react';

const LOCAL_STORAGE_KEY = 'a1_checkout';
const VERSION = 2; // HINT: Increment this when making breaking changes to storage format

const defaultState: A1CheckoutStorageObject = {
    cart: {},
    currentEventId: null,
    paymentProcess: null,
    v: VERSION,
};

const initialCartState: A1CheckoutCartObject = {
    quantities: {},
    addons: {},
    flexiblePricing: {},
    locks: {},
    referralCodes: [],
    hold: {
        id: null,
        expiresAt: null,
        visitorId: null,
    },
};

export const CartContext = createContext<ICartContext>({
    currentEventId: null,
    processedOrderId: null,
    cart: {} as A1CheckoutCartObject,
    getCalculations: () => ({ feeItems: [], lineItems: [], total: 0 }),
    getCart: () => ({} as A1CheckoutCartObject),
    setCart: () => ({} as A1CheckoutCartObject),
    setCurrentEventId: () => {},
    setProcessedOrderId: () => {},
    resetCart: () => {},
    setTicketQuantity: () => {},
    setAddonQuantity: () => {},
    setFlexibleTicketQty: () => {},
    addUnlockCode: () => '',
    removeUnlockCode: () => {},
    isUnlocked: () => [false, []],
    pairIsUnlocked: () => false,
    addReferralCode: () => {},
    inputtedUnlockCodes: [],
    hasLocks: false,
    sanitizeCart: () => {},
});

export const CartProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setState] = useState<A1CheckoutStorageObject>(() => {
        try {
            const stored = localStorage.getItem(LOCAL_STORAGE_KEY);

            if (stored) {
                const parsed = JSON.parse(stored);

                if (parsed?.v === VERSION) {
                    console.info('Using stored cart');
                    return parsed;
                }
            }

            console.info('No stored cart found, using default');
            return defaultState;
        } catch (err) {
            Sentry.captureException(err);
            return defaultState;
        }
    });

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    }, [state]);

    const setCart = useCallback((partial: Partial<A1CheckoutCartObject>) => {
        setState((state) => {
            if (!state?.currentEventId) {
                console.error('currentEventId not set');
                return state;
            }

            return {
                ...state,
                cart: {
                    ...(state?.cart ?? {}),
                    [state.currentEventId]: {
                        ...initialCartState,
                        ...(state?.cart?.[state.currentEventId] ?? {}),
                        ...partial,
                    },
                },
            };
        });
    }, []);

    const getCart = useCallback(
        (eventId?: string) => {
            if (eventId) {
                return (state?.cart?.[eventId] ?? {}) as A1CheckoutCartObject;
            } else if (state.currentEventId) {
                return (state?.cart?.[state.currentEventId] ?? {}) as A1CheckoutCartObject;
            } else {
                console.error('currentEventId not set');
                return {} as A1CheckoutCartObject;
            }
        },
        [state]
    );

    const resetCart = useCallback(
        (eventId?: string) => {
            if (!eventId) {
                return;
            }

            const currentCart = getCart(eventId);
            const updatedLocks = { ...currentCart.locks };

            for (const key of Object.keys(updatedLocks)) {
                updatedLocks[key].inputtedUnlockCodes = [];
            }

            const updatedCart: A1CheckoutCartObject = {
                ...currentCart,
                locks: updatedLocks,
                quantities: {},
                addons: {},
                flexiblePricing: {},
            };

            setState((state) => {
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        [eventId]: updatedCart,
                    },
                };
            });
        },
        [getCart]
    );

    const setTicketQuantity = useCallback(
        (categoryId: string, quantity: number) => {
            const quantities = { ...getCart()?.quantities };

            if (quantity === 0) {
                delete quantities[categoryId];

                if (Object.keys(quantities).length === 0) {
                    setCart({ addons: {} });
                }
            } else {
                quantities[categoryId] = quantity;
            }

            setCart({
                quantities,
            });
        },
        [setCart, getCart]
    );

    const setAddonQuantity = useCallback(
        (categoryId: string, variationId: string, quantity: number) => {
            const addons = { ...getCart()?.addons };
            const key = addonIdsToKey(categoryId, variationId);

            if (quantity === 0) {
                delete addons[key];
            } else {
                addons[key] = quantity;
            }

            setCart({
                addons,
            });
        },
        [setCart, getCart]
    );

    const setFlexibleTicketQty = useCallback(
        (categoryId: string, amount: number, fees: TicketCategoryUnitPrice[]) => {
            const flexiblePricing = { ...getCart()?.flexiblePricing };

            if (amount === 0) {
                delete flexiblePricing[categoryId];
            } else {
                flexiblePricing[categoryId] = {
                    amount,
                    fees,
                };
            }

            setCart({
                flexiblePricing,
            });
        },
        [setCart, getCart]
    );

    const getCalculations = useCallback(
        (event: CommonEventObject) => {
            const localCart = getCart(event?.id);

            let lineItems: LineItem[] = [];
            let feeItems: FeeItem[] = [];
            let total = 0;

            if (localCart?.quantities || localCart?.addons) {
                lineItems = convertCartToLineItems(localCart, event);
                feeItems = extractFeeItemsFromLineItems(lineItems);
                total = calculateTotal(lineItems, feeItems);
            }

            return {
                lineItems,
                feeItems,
                total,
            };
        },
        [getCart]
    );

    const sanitizeCart = useCallback(
        (event: OrderSummaryEventQuery['event'], resetQuantities = false) => {
            const cart = getCart(event?.id);

            const sanitizedLocks: A1CheckoutCartObject['locks'] = {};
            const sanitizedTicketQuantities: A1CheckoutCartObject['quantities'] = {};
            const sanitizedAddonQuantities: A1CheckoutCartObject['addons'] = {};
            const sanitizedFlexiblePricingQuantities: A1CheckoutCartObject['flexiblePricing'] = {};

            if (event?.ticketCategories) {
                for (const category of event.ticketCategories) {
                    if (category?.hashedUnlockCode && category.hashedUnlockCode.length > 0) {
                        sanitizedLocks[category.categoryId] = {
                            hashedUnlockCodes: [...category.hashedUnlockCode],
                            inputtedUnlockCodes: [],
                            pairedUnlockCodes: [],
                        };

                        if (
                            !resetQuantities &&
                            cart?.locks?.[category.categoryId]?.inputtedUnlockCodes?.length > 0
                        ) {
                            sanitizedLocks[category.categoryId].inputtedUnlockCodes = [
                                ...cart.locks[category.categoryId].inputtedUnlockCodes,
                            ];
                        }
                    }
                }
            }

            if (event?.addonCategories) {
                for (const category of event.addonCategories) {
                    if (!sanitizedLocks[category.categoryId]) {
                        sanitizedLocks[category.categoryId] = {
                            hashedUnlockCodes: [],
                            inputtedUnlockCodes: [],
                            pairedUnlockCodes: [],
                        };
                    }

                    if (category?.hashedUnlockCode && category.hashedUnlockCode.length > 0) {
                        sanitizedLocks[category.categoryId].hashedUnlockCodes =
                            category.hashedUnlockCode;

                        if (
                            !resetQuantities &&
                            cart?.locks?.[category.categoryId]?.inputtedUnlockCodes?.length > 0
                        ) {
                            sanitizedLocks[category.categoryId].inputtedUnlockCodes = [
                                ...cart.locks[category.categoryId].inputtedUnlockCodes,
                            ];
                        }
                    }

                    if (category?.unlockWith) {
                        sanitizedLocks[category.categoryId].pairedUnlockCodes = Object.keys(
                            category.unlockWith
                        );

                        if (
                            !resetQuantities &&
                            cart?.locks?.[category.categoryId]?.pairedUnlockCodes?.length > 0
                        ) {
                            sanitizedLocks[category.categoryId].pairedUnlockCodes = [
                                ...new Set([
                                    ...sanitizedLocks[category.categoryId].pairedUnlockCodes,
                                    ...Object.keys(category.unlockWith),
                                ]),
                            ];
                        }
                    }
                }
            }

            if (cart?.quantities && !resetQuantities) {
                for (const [categoryId, qty] of Object.entries(cart.quantities)) {
                    const matchingCategory = event?.ticketCategories?.find(
                        (c) => c?.categoryId === categoryId
                    );

                    if (!matchingCategory) {
                        continue;
                    }

                    if (matchingCategory.visibility === TicketCategoryVisibility.Hidden) {
                        continue;
                    }

                    if (sanitizedLocks[categoryId]) {
                        continue;
                    }

                    const maximum = matchingCategory?.quantityMax ?? 500_000;
                    const limit = matchingCategory?.quantityLimit ?? 500_000;
                    const sold = matchingCategory?.quantityIssued ?? 0;
                    const inventory = limit - sold;

                    sanitizedTicketQuantities[categoryId] = Math.min(qty, inventory, maximum);
                }
            }

            if (cart?.addons && !resetQuantities) {
                for (const [addonKey, qty] of Object.entries(cart.addons)) {
                    const [categoryId, variationId] = addonKeyToIds(addonKey);

                    const matchingCategory = event?.addonCategories?.find(
                        (c) => c.categoryId === categoryId
                    );

                    if (!matchingCategory) {
                        continue;
                    }

                    if (matchingCategory.isHidden) {
                        continue;
                    }

                    if (sanitizedLocks[categoryId]) {
                        continue;
                    }

                    const matchingVariation = matchingCategory?.variations?.find(
                        (v) => v.variationId === variationId
                    );

                    if (!matchingVariation) {
                        continue;
                    }

                    const maximum = matchingCategory?.quantityMax ?? 500_000;
                    const limit = matchingVariation?.quantityLimit ?? 500_000;
                    const sold = matchingVariation?.quantityDistributed.distributed ?? 0;
                    const inventory = limit - sold;

                    sanitizedAddonQuantities[addonKey] = Math.min(qty, inventory, maximum);
                }
            }

            if (cart?.flexiblePricing && !resetQuantities) {
                for (const [flexibleKey, flexibleOpts] of Object.entries(cart.flexiblePricing)) {
                    const matchingCategory = event?.ticketCategories?.find(
                        (c) => c?.categoryId === flexibleKey
                    );

                    if (!matchingCategory) {
                        continue;
                    }

                    if (matchingCategory.visibility === TicketCategoryVisibility.Hidden) {
                        continue;
                    }

                    sanitizedFlexiblePricingQuantities[flexibleKey] = flexibleOpts;
                }
            }

            const updatedCart: A1CheckoutCartObject = {
                referralCodes: [],
                hold: {
                    id: null,
                    visitorId: null,
                    expiresAt: null,
                },
                locks: sanitizedLocks,
                quantities: sanitizedTicketQuantities,
                addons: sanitizedAddonQuantities,
                flexiblePricing: sanitizedFlexiblePricingQuantities,
            };

            setState((state) => {
                return {
                    ...state,
                    currentEventId: event!.id,
                    cart: {
                        ...(state?.cart ?? {}),
                        [event!.id]: updatedCart,
                    },
                };
            });
        },
        [getCart]
    );

    const setCurrentEventId = useCallback(
        (eventId: string | null) => {
            if (eventId !== state.currentEventId) {
                setState((state) => ({
                    ...state,
                    currentEventId: eventId,
                }));
            }
        },
        [state]
    );

    const setProcessedOrderId = useCallback((orderId: string | null) => {
        setState((state) => ({
            ...state,
            paymentProcess: orderId,
        }));
    }, []);

    const cart = useMemo(() => {
        if (!state?.currentEventId || !state?.cart?.[state.currentEventId]) {
            return {} as A1CheckoutCartObject;
        }

        return state.cart[state.currentEventId];
    }, [state]);

    const currentEventId = useMemo(() => state.currentEventId, [state]);
    const processedOrderId = useMemo(() => state.paymentProcess, [state]);

    const inputtedUnlockCodes = useMemo(() => {
        return Array.from(
            new Set(Object.values(cart?.locks ?? {}).flatMap((lock) => lock.inputtedUnlockCodes))
        );
    }, [cart?.locks]);

    const addUnlockCode = (...codes: string[]) => {
        let error: string = '';

        setState((currentState) => {
            if (!currentState?.currentEventId) {
                console.error('currentEventId not set');
                return currentState; // Return unchanged state if no eventId
            }

            const cart = currentState?.cart?.[currentState.currentEventId];

            if (!cart) {
                return currentState; // Return unchanged state if no cart exists for event
            }

            const inputtedUnlockCodes = Array.from(
                new Set(
                    Object.values(cart?.locks ?? {}).flatMap((lock) => lock.inputtedUnlockCodes)
                )
            );

            const updatedLocks = {
                ...cart?.locks,
            };

            const allUsedCodes = inputtedUnlockCodes.map((code) => code.toUpperCase().trim());

            const duplicateCodes = codes.filter((code) =>
                allUsedCodes.includes(code.toUpperCase().trim())
            );

            if (duplicateCodes.length > 0) {
                console.error(`Code ${duplicateCodes[0]} has already been used`);
                error = `Code ${duplicateCodes[0]} has already been used`;
                return currentState;
            }

            let foundMatch = false;

            for (const [categoryId, { hashedUnlockCodes, inputtedUnlockCodes }] of Object.entries(
                cart?.locks ?? {}
            )) {
                for (const code of codes) {
                    const codeUpper = code?.toUpperCase().trim() ?? '';
                    const hashedCode = sha256(codeUpper).toString();

                    if (hashedUnlockCodes.includes(hashedCode)) {
                        if (!inputtedUnlockCodes.includes(code)) {
                            updatedLocks[categoryId].inputtedUnlockCodes.push(code);
                        }
                        foundMatch = true;
                    }
                }
            }

            if (!foundMatch) {
                console.error('Invalid unlock code');
                error = 'Invalid unlock code';
                return currentState;
            }

            // Return the new state with updated cart and locks
            return {
                ...currentState,
                cart: {
                    ...currentState.cart,
                    [currentState.currentEventId]: {
                        ...cart,
                        locks: updatedLocks,
                    },
                },
            };
        });

        return error;
    };

    const removeUnlockCode = useCallback(
        (...codes: string[]) => {
            const updatedLocks = {
                ...cart?.locks,
            };

            const standardizedCodes = codes.map((c) => c?.toUpperCase().trim());
            const affectedCategories: string[] = [];

            for (const [categoryId, { inputtedUnlockCodes }] of Object.entries(cart?.locks ?? {})) {
                const hadMatchingCode = inputtedUnlockCodes.some((c) =>
                    standardizedCodes.includes(c.toUpperCase().trim())
                );

                if (hadMatchingCode) {
                    affectedCategories.push(categoryId);
                }

                updatedLocks[categoryId].inputtedUnlockCodes = inputtedUnlockCodes.filter(
                    (c) => !standardizedCodes.includes(c.toUpperCase().trim())
                );
            }

            const updatedQuantities = { ...cart.quantities };
            const updatedAddons = { ...cart.addons };

            affectedCategories.forEach((categoryId) => {
                delete updatedQuantities[categoryId];

                Object.keys(updatedAddons).forEach((addonKey) => {
                    if (addonKey.startsWith(categoryId + '__')) {
                        delete updatedAddons[addonKey];
                    }
                });
            });

            setCart({
                locks: updatedLocks,
                quantities: updatedQuantities,
                addons: updatedAddons,
            });
        },
        [setCart, cart]
    );

    const isUnlocked = useCallback(
        (categoryId: string): readonly [boolean, readonly string[]] => {
            if (!cart?.locks) {
                return [false, []] as const;
            }

            const categoryLocks = cart.locks[categoryId];

            if (!categoryLocks) {
                return [true, []] as const;
            }

            // HINT: If the number of inputted unlock codes matches the number of hashed unlock codes,
            // then all unlock codes have been correctly entered, meaning the category is unlocked
            return [
                categoryLocks.inputtedUnlockCodes.length === categoryLocks.hashedUnlockCodes.length,
                categoryLocks.inputtedUnlockCodes,
            ] as const;
        },
        [cart.locks]
    );

    const pairIsUnlocked = useCallback(
        (categoryId: string) => {
            const addonLocked = cart.locks[categoryId].pairedUnlockCodes;
            const cartTickets = Object.keys(cart.quantities);
            const hasAnyRequiredTicket = addonLocked.some((ticketId) =>
                cartTickets.includes(ticketId)
            );

            if (!hasAnyRequiredTicket) {
                const cartAddons = Object.keys(cart.addons);
                cartAddons.map((id) => {
                    const [newCategoryId] = addonKeyToIds(id);

                    if (categoryId === newCategoryId) {
                        delete cart.addons[id];
                    }
                });
            }

            return hasAnyRequiredTicket;
        },
        [cart.addons, cart.locks, cart.quantities]
    );

    const hasLocks = useMemo(() => {
        return Object.values(cart?.locks ?? {}).some((lock) => lock.hashedUnlockCodes.length > 0);
    }, [cart?.locks]);

    const addReferralCode = useCallback(
        (referral: string) => {
            const newReferrals = [referral];

            setCart({ referralCodes: newReferrals });
        },
        [setCart]
    );

    return (
        <CartContext.Provider
            value={{
                currentEventId,
                setCurrentEventId,
                processedOrderId,
                setProcessedOrderId,
                cart,
                getCart,
                setCart,
                getCalculations,
                resetCart,
                setTicketQuantity,
                setAddonQuantity,
                setFlexibleTicketQty,
                addUnlockCode,
                removeUnlockCode,
                isUnlocked,
                pairIsUnlocked,
                inputtedUnlockCodes,
                hasLocks,
                addReferralCode,
                sanitizeCart,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
