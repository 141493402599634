import { Box, Typography, Card } from '@mui/joy';
import { StarsRounded } from '@mui/icons-material';
import { AddonCategoryObject } from '../../../graphql/graphql';

import { StyledButton } from '../../common/styled/ActionablesJoy.styled';

import { getPriceDetails } from '../../../utils/addon/addon-helpers';
import { sanitizeId } from '@/utils/validators';

interface PairedAddonEmbeddedCardProps {
    organizerColor: string;
    addonData: AddonCategoryObject;
}

export default function PairedAddonEmbeddedCard({
    organizerColor,
    addonData,
}: PairedAddonEmbeddedCardProps) {
    const scrollElementIntoViewWithOffset = (elementId: string, offset: number) => {
        const element = document.getElementById(elementId);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - offset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };

    const ImageBox = () => {
        if (addonData.imageKey) {
            return (
                <img
                    src={`https://media.admitone.com/addons/${addonData.imageKey}?width=48&height=48&fit=fill&fill=blur`}
                    alt={'image name'}
                    style={{
                        borderRadius: 8,
                        cursor: 'pointer',
                    }}
                />
            );
        }

        return (
            <Box
                sx={{
                    width: 48,
                    height: 48,
                    background: organizerColor,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 8,
                }}
            >
                <StarsRounded sx={{ width: 60, height: 60, color: 'white' }} />
            </Box>
        );
    };

    return (
        <Card sx={{ padding: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    padding: 0,
                    justifyContent: 'space-between',
                    flexDirection: { sm: 'row', xs: 'column' },
                }}
            >
                <Box sx={{ display: 'flex', flex: 1 }}>
                    <Box sx={{ width: 48, height: 48 }}>{ImageBox()}</Box>
                    <Box sx={{ paddingX: 1, flex: 1 }}>
                        <Typography level="title-sm">{addonData.name}</Typography>
                        <Typography
                            level="title-sm"
                            sx={{ color: (theme) => theme.palette.text.tertiary }}
                        >
                            {addonData?.variations &&
                                getPriceDetails(addonData.variations).lowestPrice}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ paddingTop: { sm: 0, xs: 1 }, textAlign: 'right' }}>
                    <StyledButton
                        onClick={() =>
                            scrollElementIntoViewWithOffset(
                                `addon-card-${sanitizeId(addonData.name)}`,
                                60
                            )
                        }
                        disabled={false}
                        backgroundcolor={organizerColor}
                        id={`go-to-addon-card-${sanitizeId(addonData.name)}`}
                        size="md"
                    >
                        View Add-on
                    </StyledButton>
                </Box>
            </Box>
        </Card>
    );
}
