import { useContext, useState } from 'react';

// Mui
import { ThemeProvider } from '../../ThemeProvider';
import { Drawer, Paper } from '@mui/material';
import { Box, Button, Container, Stack, Typography } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';

// Shared
import HeaderJoyComponent from '../common/HeaderJoy.component';

// Page Components
import PaymentTimer from './paymentTimerComponent.tsx';
import CheckoutCard from '../orderSummary/checkoutCardJoy.component';
import PaymentLoadedEventInfo from './paymentEventInfo.component';
import TermsOfServiceContent from './paymentToSContent.tsx';
import PaymentForm from './paymentForm.component';

// Types
import { PaymentPageEventQuery } from '../../graphql/graphql';

import { inputHeight1 } from '../../utils/sharedStyleVariables';
import { CartContext } from '../providers/cart.tsx';
import ExpressPayment from './paymentExpressPayment.tsx';
import { isReallyEmpty } from '@/utils/validators.ts';

// Form

interface PaymentLoadedProps {
    event: PaymentPageEventQuery['event'];
    organizerPrimaryColor: string;
}

export default function PaymentLoaded({ event, organizerPrimaryColor }: PaymentLoadedProps) {
    const { cart, getCalculations } = useContext(CartContext);

    const [termsOfServiceShowing, setTermsOfServiceShowing] = useState(false);
    const [errorAlert, setErrorAlert] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const { lineItems, total } = getCalculations(event);

    if (lineItems.length === 0) {
        window.location.href = `/${event?.shortEventId}`;
    }

    const hasAdditionalTerms =
        event?.additionalTerms != null && !isReallyEmpty(event?.additionalTerms);

    return (
        <ThemeProvider>
            {lineItems.length === 0 ? (
                <></>
            ) : (
                <>
                    <Drawer
                        anchor={'right'}
                        onClose={() => setTermsOfServiceShowing(false)}
                        aria-labelledby="customized-dialog-title"
                        open={termsOfServiceShowing}
                    >
                        <Paper sx={{ width: { md: '50vw', xs: '90vw' } }}>
                            <Box sx={{ textAlign: 'left', padding: '15px' }}>
                                <Button
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: organizerPrimaryColor,
                                        mb: '15px',
                                        mt: 1,
                                        cursor: 'pointer',
                                        fontSize: '13px',
                                    }}
                                    onClick={() => setTermsOfServiceShowing(false)}
                                >
                                    <CloseIcon sx={{ fontSize: 19, mr: '8px' }} />
                                    <Typography
                                        sx={{ textTransform: 'uppercase', fontWeight: 300 }}
                                    >
                                        Close
                                    </Typography>
                                </Button>
                                <TermsOfServiceContent />
                            </Box>
                        </Paper>
                    </Drawer>

                    <PaymentTimer organizerPrimaryColor={organizerPrimaryColor} />

                    <Container sx={{ paddingBottom: 7.5, paddingTop: { xs: 0, md: 15 } }}>
                        <HeaderJoyComponent
                            hasAdditionalTerms={hasAdditionalTerms}
                            previousPage={
                                !hasAdditionalTerms
                                    ? `/${event?.shortEventId}`
                                    : `/additional-terms`
                            }
                            errorAlert={errorAlert}
                            setErrorAlert={setErrorAlert}
                            step={!hasAdditionalTerms ? 2 : 3}
                            hold={cart.hold}
                            organizerPrimaryColor={organizerPrimaryColor}
                            headerTitle={'Checkout'}
                        />

                        {/* Sub Header................................................................................ */}

                        <PaymentLoadedEventInfo
                            event={event}
                            organizerPrimaryColor={organizerPrimaryColor}
                        />

                        {/* Sub Header................................................................................ */}

                        <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                gridGap: { md: 122, sm: 22, xs: 22 },
                                flexDirection: {
                                    md: 'row',
                                    sm: 'column-reverse',
                                    xs: 'column-reverse',
                                },
                            }}
                        >
                            {/* Payment Form................................................. */}
                            <Stack>
                                {hasAdditionalTerms && total > 0 && (
                                    <ExpressPayment
                                        event={event}
                                        total={total}
                                        setErrorAlert={setErrorAlert}
                                        specialRequests={cart?.checkoutNotes}
                                        hold={cart?.hold}
                                        setLoading={setLoading}
                                    />
                                )}
                                <PaymentForm
                                    setTermsOfServiceShowing={setTermsOfServiceShowing}
                                    organizerPrimaryColor={organizerPrimaryColor}
                                    setErrorAlert={setErrorAlert}
                                    total={total}
                                    event={event}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                            </Stack>

                            {/* Summary Box................................................. */}
                            <Box
                                sx={{
                                    flex: '1',
                                    position: 'relative',
                                    width: { xs: '100%', md: '50%' },
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: '100%',
                                        marginBottom: inputHeight1,
                                        position: 'sticky',
                                        top: { xs: 15, md: 55 },
                                        minHeight: '373.38px',
                                        borderRadius: '4px',
                                        zIndex: '999',
                                    }}
                                >
                                    <CheckoutCard
                                        hasAdditionalTerms={hasAdditionalTerms}
                                        event={event}
                                        organizerPrimaryColor={organizerPrimaryColor}
                                        disablePurchase={true}
                                        paymentPage={true}
                                        currency={event?.currency || 'CAD'}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </>
            )}
        </ThemeProvider>
    );
}
