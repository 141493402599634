export const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const domainRegex = /@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) && domainRegex.test(email);
};

export function sanitizeId(str: string, useHyphens = true) {
    if (!str) return '';

    // Convert to lowercase and trim
    let sanitized = str.toLowerCase().trim();

    if (useHyphens) {
        // Replace spaces with hyphens
        sanitized = sanitized.replace(/\s+/g, '-');
    } else {
        // Remove all spaces and non-alphanumeric characters
        sanitized = sanitized.replace(/[^a-z0-9]/g, '');
    }

    return sanitized;
}

export function isReallyEmpty(str: string) {
    const withoutTags = str.replace(/<[^>]*>/g, '');

    return withoutTags.trim().length === 0;
}
