import { PaymentPageEventQuery } from '@/graphql/graphql';
import { Divider, Box, Stack, Typography } from '@mui/joy';
import { ApplePayButtonComponent } from '../orderSummary/ApplePayButton.component';
import { A1CheckoutCartObject } from '@/types/common';
import { GooglePayButtonComponent } from '../orderSummary/GooglePayButton.component';

interface ExpressPaymentProps {
    event: PaymentPageEventQuery['event'];
    setErrorAlert: (err: string) => void;
    total: number;
    specialRequests?: string;
    hold: A1CheckoutCartObject['hold'];
    setLoading: (status: boolean) => void;
}

export default function ExpressPayment({
    event,
    total,
    setErrorAlert,
    specialRequests,
    hold,
    setLoading,
}: ExpressPaymentProps) {
    return (
        <Stack sx={{ mt: 2 }}>
            <Typography level="title-md" sx={{ mb: 2 }}>
                Express Pay
            </Typography>
            <Box sx={{ mb: 2, '& button': { width: '100%' } }}>
                <ApplePayButtonComponent
                    event={event! as PaymentPageEventQuery['event']}
                    chargeAmount={total}
                    chargeCurrency={event!.currency}
                    checkoutNotes={specialRequests}
                    setErrorAlert={setErrorAlert!}
                    cartHold={hold}
                />
            </Box>
            <Box sx={{ mb: 2 }}>
                <GooglePayButtonComponent
                    event={event! as PaymentPageEventQuery['event']}
                    chargeAmount={total}
                    chargeCurrency={event!.currency}
                    checkoutNotes={specialRequests}
                    setLoading={setLoading}
                    setErrorAlert={setErrorAlert!}
                    cartHold={hold}
                />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mb: 1.5,
                }}
            >
                <Divider sx={{ flexGrow: 1, alignSelf: 'center' }} />
                <Typography sx={{ px: 2 }}>OR</Typography>
                <Divider sx={{ flexGrow: 1, alignSelf: 'center' }} />
            </Box>
        </Stack>
    );
}
