import GooglePayButton from '@google-pay/button-react';
import { NUVEI_ENV, NUVEI_MERCHANT_ID } from '../../config';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Dinero from 'dinero.js';
import {
    CreateCheckoutSessionDocument,
    CreateHoldDocument,
    CreateHoldInput,
    CreateOrderDocument,
    CreateOrderMutationVariables,
    DeleteHoldDocument,
    OrderSummaryEventQuery,
    PaymentPageEventQuery,
} from '../../graphql/graphql.ts';
import { v4 as uuidv4 } from 'uuid';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router';
import { createFingerPrint } from '../../utils/createFingerPrint.ts';
import { CartContext } from '../providers/cart.tsx';
import * as Sentry from '@sentry/react';
import {
    formatFlexiblePricingForHold,
    formatFlexiblePricingForTickets,
} from '@/utils/flexiblePricing/flexiblePricing-helper.ts';
import { A1CheckoutCartObject } from '@/types/common.ts';

interface Props {
    event: OrderSummaryEventQuery['event'] | PaymentPageEventQuery['event'];
    chargeAmount: number;
    chargeCurrency: string;
    setLoading: (status: boolean) => void;
    checkoutNotes?: string;
    setErrorAlert: (err: string) => void;
    cartHold?: A1CheckoutCartObject['hold'];
}

const isProd = NUVEI_ENV === 'prod';

export const GooglePayButtonComponent: FC<Props> = ({
    event,
    chargeAmount,
    chargeCurrency,
    checkoutNotes,
    setLoading,
    setErrorAlert,
    cartHold,
}) => {
    const apollo = useApolloClient();
    const history = useHistory();
    const chargeDinero = useMemo(
        () => Dinero({ amount: chargeAmount, currency: chargeCurrency as Dinero.Currency }),
        [chargeCurrency, chargeAmount]
    );

    const { cart, getCart, setCart, setProcessedOrderId, inputtedUnlockCodes } =
        useContext(CartContext);

    const [request, setRequest] = useState<google.payments.api.PaymentDataRequest>({
        apiVersion: 2,
        apiVersionMinor: 0,
        merchantInfo: {
            merchantId: String(NUVEI_MERCHANT_ID),
            merchantName: 'AdmitONE',
        },
        transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'Total',
            totalPrice: chargeDinero.toUnit().toString(),
            currencyCode: chargeDinero.getCurrency(),
            countryCode: 'CA',
        },
        emailRequired: true,
        allowedPaymentMethods: [
            {
                type: 'CARD',
                parameters: {
                    allowedAuthMethods: ['PAN_ONLY'],
                    allowedCardNetworks: ['VISA', 'MASTERCARD', 'AMEX'],
                    billingAddressRequired: true,
                    billingAddressParameters: {
                        format: event?.requirePurchaserAddress ? 'FULL' : 'MIN',
                        phoneNumberRequired: true,
                    },
                },
                tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                        gateway: 'nuveidigital',
                        gatewayMerchantId: isProd ? 'nuveidigital' : 'googletest',
                    },
                },
            },
        ],
    });

    const [sessionToken, setSessionToken] = useState('');
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const run = async () => {
            try {
                const sessionCreationResponse = await apollo.mutate({
                    mutation: CreateCheckoutSessionDocument,
                });

                const token = sessionCreationResponse?.data?.createCheckoutSession?.sessionToken;

                if (!token) {
                    setErrorAlert('Failed to create payment session. Please try again.');
                    return;
                }

                setSessionToken(token);

                const nuveiBaseUrl = isProd
                    ? 'https://secure.safecharge.com'
                    : 'https://ppp-test.nuvei.com';

                const response = await fetch(
                    `${nuveiBaseUrl}/ppp/api/v1/googlePayMerchantInfoJwt.do?merchantOrigin=${window.location.hostname}&sessionToken=${token}`
                );

                if (response.ok) {
                    const body = await response.json();

                    if (body['status'] === 'SUCCESS') {
                        setRequest((state) => ({
                            ...state,
                            merchantInfo: body['merchantInfo'],
                        }));

                        setIsReady(true);
                    }
                }
            } catch (err) {
                console.error(err);
                Sentry.captureException(err);
            }
        };

        void run();
    }, []);

    useEffect(() => {
        setRequest((state) => ({
            ...state,
            transactionInfo: {
                ...state.transactionInfo,
                totalPrice: chargeDinero.toUnit().toString(),
                currencyCode: chargeDinero.getCurrency(),
            },
        }));
    }, [setRequest, chargeDinero]);

    const onLoadPaymentData = async (paymentData: google.payments.api.PaymentData) => {
        try {
            setLoading(true);

            let hold;

            if (!cartHold) {
                const fingerprint = await createFingerPrint();

                const payload: CreateHoldInput = {
                    fingerprint,
                    addons: cart.addons,
                    eventId: event!.id,
                    unlockCodes: inputtedUnlockCodes,
                    flexibleAmounts: formatFlexiblePricingForHold(cart?.flexiblePricing),
                    tickets: {
                        ...cart.quantities,
                        ...formatFlexiblePricingForTickets(cart?.flexiblePricing),
                    },
                    charged: {
                        amount: chargeDinero.getAmount(),
                        currency: chargeDinero.getCurrency(),
                    },
                    tosAcceptanceDate: new Date().toISOString(),
                };

                if (event?.checkoutCustomFields?.[0]?.id && checkoutNotes?.trim()) {
                    payload.customValues = {
                        [event.checkoutCustomFields[0].id]: checkoutNotes.trim(),
                    };
                }

                const { data } = await apollo.mutate({
                    mutation: CreateHoldDocument,
                    variables: {
                        payload,
                    },
                });

                if (!data?.createHold?.id) {
                    console.error('failed hold creation');
                    return;
                }

                setCart({
                    hold: {
                        id: data.createHold.id,
                        expiresAt: data.createHold.expiresAt,
                        visitorId: fingerprint,
                    },
                });

                hold = {
                    id: data?.createHold.id as string,
                    fingerprint: fingerprint,
                };
            } else {
                hold = {
                    id: cartHold.id as string,
                    fingerprint: cartHold.visitorId as string,
                };
            }

            if (!paymentData?.paymentMethodData) {
                console.error('failed to retrieve payment method from Google');
                return;
            }

            const idempotencyKey = uuidv4();

            const variables: CreateOrderMutationVariables = {
                payload: {
                    profile: {
                        firstName: '',
                        lastName: paymentData.paymentMethodData.info?.billingAddress?.name ?? '',
                        phone:
                            paymentData.paymentMethodData.info?.billingAddress?.phoneNumber ?? '',
                        email: paymentData.email!,
                        streetAddress:
                            paymentData.paymentMethodData.info?.billingAddress?.address1 ?? '',
                        city: paymentData.paymentMethodData.info?.billingAddress?.locality ?? '',
                        province:
                            paymentData.paymentMethodData.info?.billingAddress
                                ?.administrativeArea ?? '',
                        postalCode:
                            paymentData.paymentMethodData.info?.billingAddress?.postalCode ?? '',
                        country:
                            paymentData.paymentMethodData.info?.billingAddress?.countryCode ?? 'CA',
                    },
                    paymentRequest: {
                        clientRequestId: idempotencyKey,
                        sessionToken: sessionToken,
                        googlePayToken: JSON.stringify(paymentData.paymentMethodData),
                    },
                    referral: cart?.referralCodes?.[0] ?? null,
                    idempotencyKey: idempotencyKey,
                    hold: {
                        id: hold.id,
                        fingerprint: hold.fingerprint,
                    },
                },
            };

            const orderCreationResponse = await apollo.mutate({
                mutation: CreateOrderDocument,
                variables,
            });

            if (orderCreationResponse?.data?.createInternalOrder) {
                setProcessedOrderId(orderCreationResponse.data.createInternalOrder);

                setCart({
                    hold: undefined,
                });

                history.push('/confirmation');
            }
        } catch (error) {
            console.error(error);

            if (error instanceof Error) {
                setErrorAlert(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const onCancel = async () => {
        const { hold } = getCart();

        if (hold?.id) {
            try {
                const resp = await apollo.mutate({
                    mutation: DeleteHoldDocument,
                    variables: {
                        deleteHoldId: hold.id,
                    },
                });

                if (resp.data?.deleteHold) {
                    setCart({ hold: undefined });
                } else {
                    console.error(resp?.errors);
                }
            } catch (err) {
                console.error(err);
                Sentry.captureException(err);
            }
        }
    };

    if (!isReady) {
        return null;
    }

    if (chargeDinero.getCurrency() !== 'CAD') {
        return null;
    }

    if (chargeDinero.isZero()) {
        return null;
    }

    return (
        <>
            <GooglePayButton
                style={{
                    width: '100%',
                    height: 48,
                }}
                buttonRadius={4}
                buttonSizeMode="fill"
                environment={isProd ? 'PRODUCTION' : 'TEST'}
                paymentRequest={request}
                onCancel={onCancel}
                onLoadPaymentData={onLoadPaymentData}
                existingPaymentMethodRequired={false}
            />
        </>
    );
};
