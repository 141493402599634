import { useContext, useMemo, useRef, useState } from 'react';
import {
    AdditionTermsPageEventQuery,
    CreateHoldDocument,
    CreateHoldInput,
} from '@/graphql/graphql';
import parse from 'html-react-parser';
import { Box, Button, FormControl, FormLabel, Input } from '@mui/joy';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Checkbox, Typography } from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { NavLink, useHistory } from 'react-router-dom';
import { darken, lighten } from 'polished';
import { createFingerPrint } from '@/utils/createFingerPrint';
import { getIpAddress } from '@/utils/getIpAddress';
import { CartContext } from '../providers/cart';
import {
    formatFlexiblePricingForHold,
    formatFlexiblePricingForTickets,
} from '@/utils/flexiblePricing/flexiblePricing-helper';
import { useApolloClient } from '@apollo/client';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

dayjs.extend(advancedFormat);

interface AdditionalTermsLoadedProps {
    event: AdditionTermsPageEventQuery['event'];
    setErrorAlert: (err: string) => void;
}

export default function AdditionalTermsLoaded({
    event,
    setErrorAlert,
}: AdditionalTermsLoadedProps) {
    const history = useHistory();

    const contentRef = useRef(null);

    const { cart, setCart, inputtedUnlockCodes, getCalculations } = useContext(CartContext);

    const [signature, setSignature] = useState<string>('');
    const [signatureCheck, setSignatureCheck] = useState<boolean>(false);
    const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
    const [disableBtn, setDisableBtn] = useState<boolean>(false);

    const apollo = useApolloClient();

    const { total } = useMemo(() => getCalculations(event), [getCalculations, event]);

    const disableButton = signature.length < 2 || !signatureCheck || !agreeToTerms || disableBtn;

    const onSubmit = async () => {
        setDisableBtn(true);
        try {
            const checkoutNotes = cart?.checkoutNotes;

            const ipAddress = await getIpAddress();

            const fingerprint = await createFingerPrint();

            const payload: CreateHoldInput = {
                fingerprint,
                eventId: event?.id,
                unlockCodes: inputtedUnlockCodes,
                flexibleAmounts: formatFlexiblePricingForHold(cart?.flexiblePricing),
                tickets: {
                    ...cart.quantities,
                    ...formatFlexiblePricingForTickets(cart?.flexiblePricing),
                },
                charged: {
                    amount: total,
                    currency: event!.currency,
                },
                addons: cart.addons,
                tosAcceptanceDate: new Date().toISOString(),
                additionalTermsData: {
                    eSignatureAdoptionTimestamp: new Date().toISOString(),
                    termsAcceptanceTimestamp: new Date().toISOString(),
                    signature,
                    browserFingerprint: fingerprint,
                    ipAddress,
                },
            };

            if (event?.checkoutCustomFields?.[0]?.id && checkoutNotes?.trim()) {
                payload.customValues = {
                    [event.checkoutCustomFields[0].id]: checkoutNotes.trim(),
                };
            }

            const { data } = await apollo.mutate({
                mutation: CreateHoldDocument,
                variables: {
                    payload,
                },
            });

            if (!data?.createHold) {
                throw Error('Hold creation failed');
            }

            setCart({
                hold: {
                    id: data.createHold.id,
                    expiresAt: data.createHold.expiresAt,
                    visitorId: fingerprint,
                },
            });

            setDisableBtn(false);
            history.push('/payment');
        } catch (err) {
            console.error(err);
            setErrorAlert('Error: There has been an issue creating your hold.');
            setDisableBtn(false);
        }
    };

    const generatePDF = async () => {
        if (!contentRef.current) return;

        try {
            const canvas = await html2canvas(contentRef.current, {
                scale: 2,
                useCORS: true,
                logging: false,
            });

            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });

            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            const padding = 10;
            const imgData = canvas.toDataURL('image/png');
            pdf.addImage(
                imgData,
                'PNG',
                padding,
                padding,
                imgWidth - padding * 2,
                imgHeight - padding * 2
            );

            if (imgHeight > pageHeight) {
                let remainingHeight = imgHeight;
                let position = 0;

                while (remainingHeight > 0) {
                    position -= pageHeight;
                    remainingHeight -= pageHeight;

                    if (remainingHeight > 0) {
                        pdf.addPage();
                        pdf.addImage(
                            imgData,
                            'PNG',
                            padding,
                            position,
                            imgWidth - padding * 2,
                            imgHeight
                        );
                    }
                }
            }

            pdf.save('terms.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    return (
        <Box sx={{ mt: 4, pb: 6 }}>
            <Box
                ref={contentRef}
                sx={{ width: '100%', height: '549px', overflowY: 'scroll', mb: 4 }}
                id="pdf-content"
            >
                {parse(event?.additionalTerms as string)}
            </Box>
            <FormControl required>
                <FormLabel sx={{ fontWeight: 600 }}>
                    Please Sign by Entering Your Full Name
                </FormLabel>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Input
                        placeholder="Please Sign by Entering Your Full Name"
                        onChange={(e) => setSignature(e.target.value)}
                        sx={{
                            width: '100%',
                            mr: 2,
                            '--Input-focusedHighlight': 'none !important',
                            '&.Joy-focused': {
                                border: 'none',
                            },
                            '& input': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                        size="lg"
                    />
                    <Button
                        size="md"
                        sx={{
                            width: { sm: '225px', xs: '100%' },
                            mt: { sm: 0, xs: 2 },
                            backgroundColor: event?.organizerColor,
                            '&:hover': {
                                backgroundColor: darken(0.1, event?.organizerColor as string),
                            },
                        }}
                        startDecorator={<SaveAltIcon />}
                        onClick={generatePDF}
                    >
                        Download Terms
                    </Button>
                </Box>
            </FormControl>
            <Typography sx={{ mt: 2, mb: 3 }}>
                Signed on <strong>{dayjs().format('MMMM Do, YYYY')}</strong>
            </Typography>
            <Box>
                <FormControl sx={{ mb: 1 }} required>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            id="signature-check"
                            checked={signatureCheck}
                            onChange={(event) => {
                                setSignatureCheck(event.target.checked);
                            }}
                            sx={{
                                '& svg': {
                                    fill: event?.organizerColor,
                                },
                                mr: 0.5,
                            }}
                        />
                        <FormLabel sx={{ m: 0, alignSelf: 'center', fontSize: 16 }}>
                            By entering my name above, I agree to use it here as an electronic
                            signature and I understand it
                            <br />
                            is as legally binding as a traditional signature.
                        </FormLabel>
                    </Box>
                </FormControl>
                <FormControl sx={{ mb: 2 }} required>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                        <Checkbox
                            id="agree-to-terms"
                            checked={agreeToTerms}
                            onChange={(event) => {
                                setAgreeToTerms(event.target.checked);
                            }}
                            sx={{
                                '& svg': {
                                    fill: event?.organizerColor,
                                },
                                mr: 0.5,
                            }}
                        />
                        <FormLabel sx={{ m: 0, alignSelf: 'center', fontSize: 16 }}>
                            I have read, understood and agree to the terms and conditions of the
                            indicated above.
                        </FormLabel>
                    </Box>
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="lg"
                    variant="outlined"
                    component={NavLink}
                    to={`/${event?.shortEventId}`}
                    sx={{
                        mr: 1,
                        border: `1px solid ${event?.organizerColor}`,
                        color: event?.organizerColor,
                        '&:hover': {
                            backgroundColor: lighten(0.5, event?.organizerColor as string),
                        },
                    }}
                >
                    Decline
                </Button>
                <Button
                    disabled={disableButton}
                    sx={{
                        backgroundColor: event?.organizerColor,
                        '&:hover': {
                            backgroundColor: darken(0.1, event?.organizerColor as string),
                        },
                    }}
                    onClick={onSubmit}
                >
                    Agree &amp; Proceed
                </Button>
            </Box>
        </Box>
    );
}
