import { ThemeProvider } from '../../ThemeProvider';
import { margin1, margin2 } from '@/utils/sharedStyleVariables';
import { Box, Container, Skeleton } from '@mui/joy';

export default function AdditionalTermsLoading() {
    return (
        <ThemeProvider>
            <>
                <Box sx={{ mb: margin1 }}>
                    <Skeleton
                        width={'100%'}
                        height={'54px'}
                        variant="rectangular"
                        animation="wave"
                    />
                </Box>
                <Container sx={{ paddingBottom: '60px' }}>
                    <Box sx={{ mb: '24px' }}>
                        <Box sx={{ mb: '40px', display: 'flex', alignItems: 'center' }}>
                            <Skeleton
                                width="100%"
                                height={25}
                                variant="rectangular"
                                animation="wave"
                                sx={{ mb: margin2 }}
                            />
                        </Box>
                        <Box sx={{ mb: margin2 }}>
                            <Skeleton
                                width={300}
                                height={48}
                                variant="rectangular"
                                animation="wave"
                            />
                        </Box>
                        <Box sx={{ mb: margin2 }}>
                            <Skeleton
                                width={537}
                                height={30}
                                variant="rectangular"
                                animation="wave"
                            />
                        </Box>
                    </Box>

                    <Box sx={{ mb: '24px' }}>
                        <Skeleton
                            width={'100%'}
                            height={570}
                            variant="rectangular"
                            animation="wave"
                        />
                    </Box>

                    <Box sx={{ mb: '12px' }}>
                        <Skeleton width={262} height={21} variant="rectangular" animation="wave" />
                    </Box>

                    <Box sx={{ mb: '16px' }}>
                        <Skeleton width="100%" height={24} variant="rectangular" animation="wave" />
                    </Box>

                    <Box sx={{ mb: '24px' }}>
                        <Skeleton width={150} height={24} variant="rectangular" animation="wave" />
                    </Box>

                    <Box>
                        <Skeleton width={750} height={80} variant="rectangular" animation="wave" />
                    </Box>
                </Container>
            </>
        </ThemeProvider>
    );
}
