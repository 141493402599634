import { useState, useEffect, useMemo, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Dinero from 'dinero.js';
import { useApolloClient } from '@apollo/client';
import {
    CreateHoldDocument,
    CreateHoldInput,
    OrderSummaryEventQuery,
    PaymentPageEventQuery,
} from '../../graphql/graphql.ts';

// StyledComponents
import { StyledButton } from '../common/styled/ActionablesJoy.styled.tsx';
import { createFingerPrint } from '../../utils/createFingerPrint.ts';
import { CartContext } from '../providers/cart.tsx';
import {
    formatFlexiblePricingForHold,
    formatFlexiblePricingForTickets,
} from '@/utils/flexiblePricing/flexiblePricing-helper.ts';
import { Box, CircularProgress, Modal, Sheet, Typography } from '@mui/joy';

interface CheckoutBtnProps {
    history: RouteComponentProps['history'];
    btnText: string;
    isAuthenticated: boolean;
    disabled: boolean;
    backgroundcolor: string;
    event: OrderSummaryEventQuery['event'] | PaymentPageEventQuery['event'];
    checkoutNotes?: string;
    chargeAmount: number;
    chargeCurrency: string;
    organizerPrimaryColor: string;
}

const staggerCheckoutEvents = ['X0EJLP', 'A7EBDK', '67edcf57c2808b01b9522b70'];

export const CheckoutBtn = ({
    history,
    btnText,
    disabled,
    backgroundcolor,
    event,
    checkoutNotes,
    chargeAmount,
    chargeCurrency,
    organizerPrimaryColor,
}: CheckoutBtnProps) => {
    const { cart, setCart, inputtedUnlockCodes } = useContext(CartContext);
    const [disableBtn, setDisableBtn] = useState<boolean>(disabled);
    const [loading, setLoading] = useState<boolean>(false);
    const [redirectTime, setRedirectTime] = useState<number>(0);
    const [queueing, setQueueing] = useState<boolean>(false);
    const apollo = useApolloClient();

    useEffect(() => {
        setDisableBtn(disabled);
    }, [disabled]);

    useEffect(() => {
        if (redirectTime <= 0) return;

        const timer = setInterval(() => {
            setRedirectTime((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [redirectTime]);

    const chargeDinero = useMemo(
        () => Dinero({ amount: chargeAmount, currency: chargeCurrency as Dinero.Currency }),
        [chargeCurrency, chargeAmount]
    );

    const onSubmit = async () => {
        setDisableBtn(true);
        setLoading(true);

        try {
            const fingerprint = await createFingerPrint();
            const payload: CreateHoldInput = {
                fingerprint,
                eventId: event?.id,
                unlockCodes: inputtedUnlockCodes,
                flexibleAmounts: formatFlexiblePricingForHold(cart?.flexiblePricing),
                tickets: {
                    ...cart.quantities,
                    ...formatFlexiblePricingForTickets(cart?.flexiblePricing),
                },
                addons: cart.addons,
                charged: {
                    amount: chargeDinero.getAmount(),
                    currency: chargeDinero.getCurrency(),
                },
                tosAcceptanceDate: new Date().toISOString(),
            };

            if (event?.checkoutCustomFields?.[0]?.id && checkoutNotes?.trim()) {
                payload.customValues = {
                    [event.checkoutCustomFields[0].id]: checkoutNotes.trim(),
                };
            }

            const { data } = await apollo.mutate({
                mutation: CreateHoldDocument,
                variables: {
                    payload,
                },
            });

            if (!data?.createHold) {
                throw Error('Hold creation failed');
            }

            setCart({
                hold: {
                    id: data.createHold.id,
                    expiresAt: data.createHold.expiresAt,
                    visitorId: fingerprint,
                },
                checkoutNotes,
            });

            setDisableBtn(false);
            setLoading(false);
            history.push('/payment');
        } catch (err) {
            console.error(err);
            alert(`Error creating hold for tickets. ${err}`);
            setDisableBtn(false);
            setLoading(false);
        }
    };

    const randomizeTime = () => {
        setLoading(true);
        setDisableBtn(true);

        const min = 4000;
        const max = 10000;
        const randomTime = Math.random() * (max - min) + min;

        const roundedUp = Math.ceil(randomTime / 1000) * 1000;

        setRedirectTime(roundedUp / 1000 + 4);

        setQueueing(true);

        setTimeout(() => {
            setTimeout(() => {
                onSubmit();
            }, 4000);
        }, roundedUp);
    };

    return (
        <>
            <StyledButton
                onClick={() => {
                    if (staggerCheckoutEvents.includes(event?.shortEventId as string)) {
                        randomizeTime();
                    } else {
                        onSubmit();
                    }
                }}
                disabled={disableBtn}
                backgroundcolor={backgroundcolor}
                id={'proceed_to_checkout'}
                size="lg"
            >
                {loading ? 'Loading' : btnText}
            </StyledButton>

            <Modal
                aria-labelledby="alert-dialog-title"
                aria-describedby="modal-alert-dialog-description"
                open={queueing}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="title-lg"
                        textColor="inherit"
                        sx={{ mb: 2 }}
                    >
                        Queueing Tickets, redirecting in {redirectTime} seconds
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <CircularProgress sx={{ color: organizerPrimaryColor }} />
                    </Box>
                    <Typography id="alert-dialog-description" level="body-lg">
                        We are queueing your order. Please do not close or refresh this browser tab.
                    </Typography>
                </Sheet>
            </Modal>
        </>
    );
};
