import { useContext, useEffect, useState } from 'react';
import { EVENTS_URL } from '../config';
import { useQuery } from '@apollo/client';
import { CartContext } from '../components/providers/cart';
import { AdditionTermsPageEventDocument } from '../graphql/graphql';
import { ThemeProvider } from '../ThemeProvider';
import AdditionalTermsLoading from '../components/additionalTerms/additionalTermsLoading';
import HeaderJoyComponent from '../components/common/HeaderJoy.component';
import { Container } from '@mui/joy';
import AdditionalTermsLoaded from '../components/additionalTerms/additionalTermsLoaded';
import { isReallyEmpty } from '../utils/validators';

export default function AdditionalTermsPage() {
    const [errorAlert, setErrorAlert] = useState<string | null>(null);

    const { currentEventId } = useContext(CartContext);

    const { loading, data, error } = useQuery(AdditionTermsPageEventDocument, {
        variables: {
            eventId: currentEventId!,
        },
        skip: !currentEventId,
    });

    useEffect(() => {
        if (!currentEventId) {
            window.location.href = EVENTS_URL;
        }
    }, [currentEventId]);

    if (!loading && error) {
        return <>Error Loading Data</>;
    }

    const organizerThemeColor = data?.event?.organizerColor || '#212B36';

    const organizerPrimaryColor =
        data?.event?.organizerType === 'poweredby' ? organizerThemeColor : '#4A7754';

    const hasAdditionalTerms =
        data?.event?.additionalTerms != null && !isReallyEmpty(data?.event?.additionalTerms);

    return (
        <ThemeProvider>
            <>
                {loading ? (
                    <>
                        <AdditionalTermsLoading />
                    </>
                ) : (
                    <Container sx={{ py: 6 }}>
                        <HeaderJoyComponent
                            hasAdditionalTerms={hasAdditionalTerms}
                            previousPage={`/${data?.event?.shortEventId}`}
                            step={2}
                            organizerPrimaryColor={organizerPrimaryColor}
                            headerTitle={'Additional Terms'}
                            setErrorAlert={setErrorAlert}
                            errorAlert={errorAlert}
                        />
                        <AdditionalTermsLoaded event={data?.event} setErrorAlert={setErrorAlert} />
                    </Container>
                )}
            </>
        </ThemeProvider>
    );
}
