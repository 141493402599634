import Dinero, { Currency } from 'dinero.js';
import { useMemo, useContext, useCallback } from 'react';

import { AddonCategoryObject, TicketCategoryObject } from '../../graphql/graphql';
import {
    CustomTicketChip,
    IncreaseDecreaseContainer,
    TicketCategoryBox,
} from './orderSummaryPageJoy.styled';
import { SaleEndDateFormatter } from '../../utils/formatter';
import { formatTag } from '../../utils/formatter';
import { sanitizeId } from '../../utils/validators';

// MUI
import { Typography, Box, Stack, Chip } from '@mui/joy';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ProductDescription from './ticketCategoryDescriptionJoy.component';
import { CartContext } from '../providers/cart';
import PairedAddonEmbeddedCard from './addons/pairedAddonEmbeddedCard';

interface TicketCategoryCardProps {
    ticketCategory: TicketCategoryObject;
    unlockCode: readonly string[];
    timezone: string;
    organizerPrimaryColor: string;
    organizerSecondaryColor?: string;
    overallLimit: boolean;
    remainingTickets?: number | null;
    currentTotalTicketsSelected: number;
    id: string;
}

export default function TicketCategoryCardJoy({
    ticketCategory,
    unlockCode,
    timezone,
    organizerPrimaryColor,
    overallLimit,
    remainingTickets,
    currentTotalTicketsSelected,
}: TicketCategoryCardProps) {
    const { cart, setTicketQuantity } = useContext(CartContext);

    const quantity = useMemo(
        () => cart?.quantities?.[ticketCategory?.categoryId as string] ?? 0,
        [cart, ticketCategory.categoryId]
    );

    const availableTickets: number = (ticketCategory?.inventory as number) ?? 0;
    const maxTicketQuantity: number = (ticketCategory?.quantityMax as number) || 10;
    const minTicketQuantity: number = (ticketCategory?.quantityMin as number) || 1;

    // Check for remaining considering current amount selected
    const availableToAdd = remainingTickets
        ? remainingTickets - currentTotalTicketsSelected
        : availableTickets;

    const isRemainingTicketsEnough = useMemo(() => {
        if (remainingTickets !== null) {
            if (ticketCategory?.isQuantityMinStrict) {
                if (availableToAdd < minTicketQuantity) {
                    return false;
                } else {
                    return true;
                }
            }
            if (!ticketCategory?.isQuantityMinStrict) {
                true;
            }
        }
        return true;
    }, [minTicketQuantity, remainingTickets, availableToAdd, ticketCategory?.isQuantityMinStrict]);

    // Remove Ticket Quantity
    const decrementQuantity = useCallback(() => {
        const availableBelowMinimum = availableTickets < minTicketQuantity;

        const shouldSetBackToZero =
            quantity - 1 < minTicketQuantity &&
            (ticketCategory?.isQuantityMinStrict ||
                (!ticketCategory?.isQuantityMinStrict && !availableBelowMinimum));

        const updatedQuantity = shouldSetBackToZero ? 0 : quantity - 1;

        setTicketQuantity(ticketCategory.categoryId, updatedQuantity);
    }, [ticketCategory, quantity, setTicketQuantity, availableTickets, minTicketQuantity]);

    // Add Ticket Quantity
    const incrementQuantity = useCallback(() => {
        // Check if available amount is lesser than total available tickets
        const availableBelowMinimum: boolean = availableToAdd < minTicketQuantity;

        // Set new temporary value
        let updatedQuantity = quantity + 1;

        // check if new value is below the minium required for a purchase
        if (updatedQuantity < minTicketQuantity) {
            // if you CANNOT buy below minium, if we have enough set to minium, otherwise to available amount
            if (ticketCategory?.isQuantityMinStrict) {
                updatedQuantity = availableBelowMinimum ? availableTickets : minTicketQuantity;
            }

            // if you CAN buy below minium when not enough, and you have enough tickets for minium (if you don't have enough available, we just add 1 as previously set)
            if (!ticketCategory?.isQuantityMinStrict && !availableBelowMinimum) {
                updatedQuantity = minTicketQuantity;
            }
        }

        setTicketQuantity(ticketCategory.categoryId, updatedQuantity);
    }, [
        ticketCategory,
        quantity,
        setTicketQuantity,
        availableTickets,
        availableToAdd,
        minTicketQuantity,
    ]);

    const checkMinAlert = () => {
        let alert = false;

        if (availableTickets < minTicketQuantity && ticketCategory.isQuantityMinStrict) {
            alert = true;
        }

        if (quantity !== 0 && quantity < minTicketQuantity && ticketCategory.isQuantityMinStrict) {
            alert = true;
        }

        return (
            <>
                {alert ? (
                    <Typography sx={{ color: 'red', alignSelf: 'center', fontSize: 12 }}>
                        Min {minTicketQuantity} per order
                    </Typography>
                ) : (
                    <></>
                )}
            </>
        );
    };

    const tagAvailable =
        ticketCategory?.ticketTag &&
        (ticketCategory.quantityIssued as number) < (ticketCategory.quantityLimit as number);

    const isTicketSoldOut =
        (ticketCategory.quantityIssued as number) >= (ticketCategory.quantityLimit as number) ||
        ticketCategory.status === 'Sold Out' ||
        ticketCategory.inventory === 0;

    const shouldRenderAddonPair = (addon: AddonCategoryObject) => {
        if (addon.isHidden || quantity === 0) {
            return false;
        }
        return true;
    };

    return (
        <TicketCategoryBox id={`ticket-card-${sanitizeId(ticketCategory.name as string)}`}>
            {tagAvailable && (
                <CustomTicketChip setcolor={organizerPrimaryColor}>
                    {formatTag(ticketCategory?.ticketTag)}
                </CustomTicketChip>
            )}
            <Box sx={{ padding: '15px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}
                >
                    <Stack sx={{ marginRight: '20px' }}>
                        <Typography>
                            {ticketCategory.name}{' '}
                            {unlockCode.length > 0 ? ` - ${unlockCode.join(', ')}` : null}
                        </Typography>
                        {unlockCode.length > 0 && (
                            <Typography
                                sx={{
                                    color: 'rgba(0,0,0,0.6)',
                                    fontSize: '12px',
                                    mt: '10px',
                                    display: 'flex',
                                }}
                            >
                                <LockOpenIcon fontSize={'inherit'} sx={{ mr: '5px' }} />{' '}
                                <span>Unlocked Ticket</span>
                            </Typography>
                        )}
                        <Typography sx={{ mt: 2, fontWeight: 600 }}>
                            {(ticketCategory?.basePrice?.amount as number) > 0
                                ? Dinero({
                                      amount: ticketCategory?.basePrice?.amount as number,
                                      currency: ticketCategory?.basePrice?.currency as Currency,
                                  })
                                      .setLocale(
                                          ticketCategory?.basePrice?.currency === 'USD'
                                              ? 'en-US'
                                              : 'en-CA'
                                      )
                                      .toFormat('$0,0.00')
                                : 'Free'}
                        </Typography>
                        {ticketCategory?.conditions &&
                        ticketCategory?.conditions?.saleWindow?.toDateVisible ? (
                            <Typography sx={{ mt: 2 }}>
                                {SaleEndDateFormatter(
                                    new Date(ticketCategory?.conditions?.saleWindow?.to),
                                    timezone
                                )}
                            </Typography>
                        ) : null}
                    </Stack>
                    {isTicketSoldOut ? (
                        <Chip>Sold Out</Chip>
                    ) : (
                        <Stack sx={{ marginLeft: '10px' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'flex-start',
                                    marginBottom: '8px',
                                    gap: 3,
                                    minWidth: '126px',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <IncreaseDecreaseContainer
                                    id={`remove-ticket-${sanitizeId(
                                        ticketCategory?.name as string
                                    )}`}
                                    disabled={quantity === 0}
                                    onClick={decrementQuantity}
                                    setcolor={organizerPrimaryColor}
                                >
                                    <RemoveIcon className="plus_minus_icon" />
                                </IncreaseDecreaseContainer>
                                <Typography level="body-md" sx={{ fontWeight: 600 }}>
                                    {quantity}
                                </Typography>
                                <IncreaseDecreaseContainer
                                    id={`add-ticket-${sanitizeId(ticketCategory?.name as string)}`}
                                    disabled={
                                        quantity >= maxTicketQuantity ||
                                        overallLimit ||
                                        quantity == availableTickets ||
                                        !isRemainingTicketsEnough
                                    }
                                    onClick={incrementQuantity}
                                    setcolor={organizerPrimaryColor}
                                >
                                    <AddIcon className="plus_minus_icon" />
                                </IncreaseDecreaseContainer>
                            </Box>
                            {checkMinAlert()}
                            {quantity === maxTicketQuantity && (
                                <Typography
                                    sx={{ color: 'red', alignSelf: 'center', fontSize: 12 }}
                                >
                                    Max {maxTicketQuantity} per order
                                </Typography>
                            )}
                        </Stack>
                    )}
                </Box>
                <ProductDescription
                    description={ticketCategory.description as string}
                    organizerPrimaryColor={organizerPrimaryColor}
                />
            </Box>

            {ticketCategory.pairedAddons && ticketCategory.pairedAddons.length > 0 && (
                <>
                    {ticketCategory.pairedAddons
                        .filter((addon) => {
                            return shouldRenderAddonPair(addon);
                        })
                        .map((addon, index) => {
                            return (
                                <Box sx={{ margin: 2, mt: index > 0 ? 1 : 0 }}>
                                    <PairedAddonEmbeddedCard
                                        organizerColor={organizerPrimaryColor}
                                        addonData={addon}
                                    />
                                </Box>
                            );
                        })}
                </>
            )}
        </TicketCategoryBox>
    );
}
