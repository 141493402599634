import { A1CheckoutCartObject } from '@/src/types/common';

export function formatFlexiblePricingForHold(
    flexiblePricing: A1CheckoutCartObject['flexiblePricing']
) {
    let flexibleHold: { [key: string]: number } = {};

    for (const [flexibleKey, flexibleOpts] of Object.entries(flexiblePricing)) {
        flexibleHold[flexibleKey] = flexibleOpts.amount;
    }

    return flexibleHold;
}

export function formatFlexiblePricingForTickets(
    flexiblePricing: A1CheckoutCartObject['flexiblePricing']
) {
    let flexibleHold: { [key: string]: number } = {};

    if (Object.entries(flexiblePricing).length === 0) {
        return;
    }

    for (const [flexibleKey] of Object.entries(flexiblePricing)) {
        flexibleHold[flexibleKey] = 1;
    }

    return flexibleHold;
}
