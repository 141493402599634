import { EVENTS_URL } from '../config';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import ConfirmationPageLoading from '../components/confirmation/ConfirmationLoading.component';
import ConfirmationPageLoaded from '../components/confirmation/ConfirmationPageLoaded.component';
import { ConfirmationPageEventDocument, ConfirmationPageEventQuery } from '../graphql/graphql';
import { useContext, useEffect } from 'react';
import { CartContext } from '../components/providers/cart.tsx';
import { isReallyEmpty } from '../utils/validators.ts';

export function ConfirmationPage() {
    const { currentEventId, processedOrderId } = useContext(CartContext);
    const history = useHistory();

    const { loading, data, error } = useQuery<ConfirmationPageEventQuery>(
        ConfirmationPageEventDocument,
        {
            variables: {
                eventId: currentEventId!,
            },
            skip: !currentEventId,
        }
    );

    useEffect(() => {
        if (!processedOrderId) {
            if (currentEventId) {
                history.push(`/${currentEventId}`);
            } else {
                window.location.href = EVENTS_URL;
            }
        }
    }, []);

    if (!loading && error) {
        return <>Error Loading Data</>;
    }
    const organizerThemeColor = data?.event?.organizerColor || '#212B36';
    const organizerPrimaryColor =
        data?.event?.organizerType === 'poweredby' ? organizerThemeColor : '#4A7754';

    const hasAdditionalTerms =
        data?.event?.additionalTerms != null && !isReallyEmpty(data?.event?.additionalTerms);

    return (
        <>
            {loading ? (
                <ConfirmationPageLoading />
            ) : (
                <ConfirmationPageLoaded
                    hasAdditionalTerms={hasAdditionalTerms}
                    event={data!.event}
                    organizerPrimaryColor={organizerPrimaryColor}
                />
            )}
        </>
    );
}
