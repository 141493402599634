import { SyntheticEvent, useContext } from 'react';
import { useApolloClient } from '@apollo/client';

import { ErrorAlert } from './styled/HeaderJoy.styled';
import { StyledATag } from './styled/ActionablesJoy.styled';
import { StyledHR } from './styled/StaticElementsJoy.styled';
import { Typography, Box, Stack } from '@mui/joy';
import HeaderJoyComponentCircle from './HeaderJoyCircle.component';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DeleteHoldDocument } from '../../graphql/graphql.ts';
import { CartContext } from '../providers/cart.tsx';
import { A1CheckoutCartObject } from '../../types/common.ts';

import * as Sentry from '@sentry/react';

interface HeaderProps {
    previousPage: string;
    step: number;
    errorAlert?: string | null;
    hold?: A1CheckoutCartObject['hold'];
    setErrorAlert?: (err: string | null) => void;
    organizerPrimaryColor: string;
    organizerSecondaryColor?: string;
    headerTitle?: string;
    hasAdditionalTerms: boolean;
}

export default function HeaderJoyComponent({
    previousPage,
    errorAlert = null,
    setErrorAlert = () => {},
    step,
    hold,
    organizerPrimaryColor,
    headerTitle,
    hasAdditionalTerms,
}: HeaderProps) {
    const { currentEventId, resetCart } = useContext(CartContext);
    const apollo = useApolloClient();

    const checkStep = async (e: SyntheticEvent, previousPage: string) => {
        e.preventDefault();

        if (step === 2 && !hasAdditionalTerms) {
            if (hold?.id) {
                try {
                    await apollo.mutate({
                        mutation: DeleteHoldDocument,
                        variables: {
                            deleteHoldId: hold?.id,
                        },
                    });
                } catch (err) {
                    console.error(err);
                    Sentry.captureException(err);
                } finally {
                    if (currentEventId) {
                        resetCart(currentEventId);

                        window.location.href = previousPage;
                    }
                }
            }
        }

        if (step === 3 && hasAdditionalTerms) {
            if (hold?.id) {
                try {
                    await apollo.mutate({
                        mutation: DeleteHoldDocument,
                        variables: {
                            deleteHoldId: hold?.id,
                        },
                    });
                } catch (err) {
                    console.error(err);
                    Sentry.captureException(err);
                } finally {
                    if (currentEventId) {
                        resetCart(currentEventId);

                        window.location.href = previousPage;
                    }
                }
            }
        } else {
            window.location.href = previousPage;
        }
    };

    return (
        <header>
            <Box sx={{ mb: 3 }}>
                <StyledATag
                    href={previousPage}
                    setcolor={organizerPrimaryColor}
                    onClick={(e) => checkStep(e, previousPage)}
                >
                    <Typography
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: '600',
                            color: organizerPrimaryColor,
                        }}
                    >
                        <ArrowBackIosNewIcon sx={{ fontSize: 14, mr: 1, strokeWidth: 6 }} />
                        Back To Previous Page
                    </Typography>
                </StyledATag>
            </Box>
            {errorAlert && (
                <ErrorAlert>
                    <Box sx={{ display: 'flex' }}>
                        <ErrorOutlineIcon sx={{ marginRight: '10px' }} />
                        <Stack>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    margin: '0 0 10px 0',
                                    color: '#fff',
                                    fontWeight: 700,
                                }}
                            >
                                Transaction Failed
                            </Typography>
                            <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                                {errorAlert}
                            </Typography>
                        </Stack>
                    </Box>
                    <CloseIcon onClick={() => setErrorAlert(null)} />
                </ErrorAlert>
            )}
            <Box sx={{ mb: 3, display: 'flex', alignItems: { md: 'center', xs: 'flex-start' } }}>
                <HeaderJoyComponentCircle
                    stepId={1}
                    checked={step >= 2}
                    minWidthMD="140px"
                    minWidthSM={hasAdditionalTerms ? '70px' : '100px'}
                    text={'Order Summary'}
                    organizerColor={organizerPrimaryColor}
                    current={step}
                />
                <StyledHR />
                {hasAdditionalTerms && (
                    <>
                        <HeaderJoyComponentCircle
                            stepId={2}
                            checked={step >= 3}
                            minWidthMD="150px"
                            minWidthSM={hasAdditionalTerms ? '80px' : '100px'}
                            text={'Additional Terms'}
                            organizerColor={organizerPrimaryColor}
                            current={step}
                        />
                        <StyledHR />
                    </>
                )}
                <HeaderJoyComponentCircle
                    stepId={hasAdditionalTerms ? 3 : 2}
                    checked={hasAdditionalTerms ? step >= 4 : step >= 3}
                    text={'Checkout'}
                    organizerColor={organizerPrimaryColor}
                    current={step}
                />
                <StyledHR />
                <HeaderJoyComponentCircle
                    stepId={hasAdditionalTerms ? 4 : 3}
                    checked={hasAdditionalTerms ? step >= 4 : step >= 3}
                    text={'Confirmation'}
                    organizerColor={organizerPrimaryColor}
                    current={step}
                />
            </Box>
            <Typography
                component="h2"
                level="title-lg"
                sx={{
                    mb: hasAdditionalTerms && step === 2 ? 0 : 3,
                    fontSize: 36,
                    textAlign: 'left',
                    fontWeight: 700,
                }}
            >
                {headerTitle}
            </Typography>
            {hasAdditionalTerms && step === 2 && (
                <Typography level="title-md">
                    You must agree with the terms below to complete the transaction.
                </Typography>
            )}
        </header>
    );
}
