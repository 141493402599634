import Dinero from 'dinero.js';
import { useContext, useState, ChangeEvent, useMemo } from 'react';

import {
    CalculateFlexibleTicketPriceDocument,
    FlexiblePricingObject,
    TicketCategoryObject,
} from '@/graphql/graphql';
import { TicketCategoryBox } from '../orderSummaryPageJoy.styled';

// MUI
import {
    Typography,
    Box,
    Stack,
    ButtonGroup,
    Button,
    IconButton,
    Input,
    FormHelperText,
    useTheme,
} from '@mui/joy';
import ProductDescription from '../ticketCategoryDescriptionJoy.component';
import { CartContext } from '../../providers/cart';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useApolloClient } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { lighten } from 'polished';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface DonationCategoryCardProps {
    ticketCategory: TicketCategoryObject;
    organizerPrimaryColor: string;
    eventId: string;
}

export default function DonationCategoryCard({
    ticketCategory,
    organizerPrimaryColor,
    eventId,
}: DonationCategoryCardProps) {
    const theme = useTheme();

    const { cart, setFlexibleTicketQty } = useContext(CartContext);

    const apollo = useApolloClient();

    const amount = useMemo(() => {
        return cart?.flexiblePricing[ticketCategory?.categoryId]?.amount ?? 0;
    }, [cart, ticketCategory?.categoryId]);

    const flexiblePricing = ticketCategory?.flexiblePricing as FlexiblePricingObject;

    const [error, setError] = useState<string | null>(null);
    const [donationAmount, setDonationAmount] = useState<string>('');
    const [disableDonationBtn, setDisableDonationBtn] = useState<boolean>(false);
    const [stepSelected, setStepSelected] = useState<number | null>(null);

    const setSelectedAmount = async (value: number, input: string) => {
        setDisableDonationBtn(true);
        try {
            const res = await apollo.query({
                query: CalculateFlexibleTicketPriceDocument,
                variables: {
                    eventId: eventId,
                    categoryId: ticketCategory?.categoryId as string,
                    amount: value,
                },
            });

            if (!res.data) {
                throw Error('Error getting api');
            }

            if (res.errors) {
                throw Error(res.errors.toString());
            }

            if (!res.data.calculateFlexibleTicketPrice) {
                throw Error('calculated fees returned null');
            }

            setFlexibleTicketQty(
                ticketCategory?.categoryId,
                value,
                res.data.calculateFlexibleTicketPrice
            );

            if (input === 'button') {
                setDonationAmount('');
                setStepSelected(value);
            } else {
                setDonationAmount((value / 100).toFixed(2).toString());
                setStepSelected(null);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setDisableDonationBtn(false);
        }
    };

    const removeFlexibleAmount = () => {
        setFlexibleTicketQty(ticketCategory?.categoryId, 0, []);
        setDonationAmount('');
    };

    const steps = ticketCategory?.flexiblePricing?.steps || [];

    const validateAmount = (value: string) => {
        const numberValue = Number(value) * 100;

        if (isNaN(numberValue)) {
            setError('Amount must be a number.');
            return null;
        }

        if (numberValue < flexiblePricing?.minAmount) {
            setError(
                `Min. Donation Amount: ${Dinero({
                    amount: flexiblePricing.minAmount,
                }).toFormat('$0,0.00')}`
            );
            return null;
        }

        if (numberValue > flexiblePricing?.maxAmount) {
            setError(
                `Max. Donation Amount: ${Dinero({
                    amount: flexiblePricing.maxAmount,
                }).toFormat('$0,0.00')}`
            );
            return null;
        }

        return numberValue;
    };

    return (
        <TicketCategoryBox>
            <Box sx={{ padding: '15px' }}>
                <Box>
                    <Stack>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                marginBottom: '8px',
                            }}
                        >
                            <Typography sx={{ mr: 2 }}>{ticketCategory.name} </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'flex-start',
                                    gap: 1,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography level="body-md" sx={{ fontWeight: 600 }}>
                                    {amount === 0 ? '$0' : Dinero({ amount }).toFormat('$0,0.00')}
                                </Typography>
                                {amount > 0 && (
                                    <IconButton
                                        variant="outlined"
                                        sx={{
                                            borderColor: organizerPrimaryColor,
                                            '&:hover': {
                                                backgroundColor: lighten(
                                                    0.5,
                                                    organizerPrimaryColor
                                                ),
                                            },
                                        }}
                                        onClick={removeFlexibleAmount}
                                    >
                                        <DeleteIcon sx={{ color: organizerPrimaryColor }} />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                        {steps.length > 0 && (
                            <>
                                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    <ButtonGroup
                                        sx={{ my: 2, flexWrap: { xs: 'wrap' }, height: '40px' }}
                                    >
                                        {steps.map((step, index) => (
                                            <Button
                                                key={`${ticketCategory?.categoryId}-${step}-${index}`}
                                                onClick={() => setSelectedAmount(step, 'button')}
                                                disabled={disableDonationBtn}
                                                sx={{
                                                    backgroundColor:
                                                        stepSelected === step
                                                            ? theme.palette.neutral[100]
                                                            : 'rgba(0,0,0,0)',
                                                }}
                                            >
                                                {Dinero({ amount: step }).toFormat('$0,0.00')}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </Box>
                                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    {Array.from(
                                        { length: Math.ceil(steps.length / 2) },
                                        (_, rowIndex) => (
                                            <Box key={rowIndex} sx={{ display: 'flex', mb: 1 }}>
                                                {steps
                                                    .slice(rowIndex * 2, rowIndex * 2 + 2)
                                                    .map((step, index) => (
                                                        <Button
                                                            variant="outlined"
                                                            key={`${ticketCategory?.categoryId}-${step}-${index}`}
                                                            onClick={() =>
                                                                setSelectedAmount(step, 'button')
                                                            }
                                                            disabled={disableDonationBtn}
                                                            sx={{
                                                                mr: 1,
                                                                border: `1px solid ${theme.palette.neutral[500]}`,
                                                                color: '#000',
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        theme.palette.neutral[100],
                                                                },
                                                                backgroundColor:
                                                                    stepSelected === step
                                                                        ? theme.palette.neutral[100]
                                                                        : 'rgba(0,0,0,0)',
                                                            }}
                                                            className={step.toString()}
                                                        >
                                                            {Dinero({ amount: step }).toFormat(
                                                                '$0,0.00'
                                                            )}
                                                        </Button>
                                                    ))}
                                            </Box>
                                        )
                                    )}
                                </Box>
                            </>
                        )}
                        <Box>
                            <Input
                                value={donationAmount}
                                disabled={disableDonationBtn}
                                autoComplete="off"
                                startDecorator={<AttachMoneyIcon />}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setDonationAmount(e.target.value)
                                }
                                placeholder={
                                    flexiblePricing?.minAmount > 0
                                        ? `Min Amount: ${Dinero({
                                              amount: flexiblePricing?.minAmount,
                                          }).toFormat('$0,0.00')}`
                                        : 'Custom Amount'
                                }
                                endDecorator={
                                    <IconButton
                                        sx={{
                                            backgroundColor: organizerPrimaryColor,
                                            color: '#fff',
                                            mr: 0,
                                        }}
                                        size="sm"
                                        onClick={() => {
                                            setError(null);

                                            const validAmount = validateAmount(donationAmount);

                                            if (validAmount !== null) {
                                                setSelectedAmount(validAmount, 'input');
                                            }
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                }
                                error={!!error}
                                sx={{
                                    height: '48px',
                                    width: '100%',
                                    '--Input-focusedHighlight': 'none !important',
                                    '&.Joy-focused': {
                                        border: 'none',
                                    },
                                    '& input': {
                                        '&:focus': {
                                            outline: 'none',
                                        },
                                    },
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setError(null);

                                        const validAmount = validateAmount(donationAmount);

                                        if (validAmount !== null) {
                                            setSelectedAmount(validAmount, 'input');
                                        }
                                    }
                                }}
                                size="md"
                            />
                        </Box>
                        {error && (
                            <FormHelperText sx={{ color: 'red', fontSize: 11, mt: 0.5 }}>
                                {error}
                            </FormHelperText>
                        )}
                    </Stack>
                </Box>
                <ProductDescription
                    description={ticketCategory.description as string}
                    organizerPrimaryColor={organizerPrimaryColor}
                />
            </Box>
        </TicketCategoryBox>
    );
}
