import { NUVEI_ENV, NUVEI_SITE_ID } from '../../config';
import { v4 as uuidv4 } from 'uuid';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import {
    CreateCheckoutSessionDocument,
    CreateHoldDocument,
    CreateHoldInput,
    CreateOrderDocument,
    CreateOrderMutationVariables,
    DeleteHoldDocument,
    OrderSummaryEventQuery,
    PaymentPageEventQuery,
} from '../../graphql/graphql.ts';
import { useHistory } from 'react-router';
import Dinero from 'dinero.js';
import { Button } from '@mui/material';
import { createFingerPrint } from '../../utils/createFingerPrint.ts';
import { CartContext } from '../providers/cart.tsx';
import * as Sentry from '@sentry/react';
import {
    formatFlexiblePricingForHold,
    formatFlexiblePricingForTickets,
} from '@/utils/flexiblePricing/flexiblePricing-helper.ts';
import { A1CheckoutCartObject } from '@/types/common.ts';

interface Props {
    event: OrderSummaryEventQuery['event'] | PaymentPageEventQuery['event'];
    chargeAmount: number;
    chargeCurrency: string;
    checkoutNotes?: string;
    setErrorAlert: (err: string) => void;
    cartHold?: A1CheckoutCartObject['hold'];
}

export const ApplePayButtonComponent: FC<Props> = ({
    event,
    chargeAmount,
    chargeCurrency,
    checkoutNotes,
    setErrorAlert,
    cartHold,
}) => {
    const isApplePayEnabled = Boolean(window?.ApplePaySession?.canMakePayments());
    const apollo = useApolloClient();
    const history = useHistory();
    const { cart, getCart, setCart, setProcessedOrderId, inputtedUnlockCodes } =
        useContext(CartContext);
    const [isProcessing, setIsProcessing] = useState(false);

    const chargeDinero = useMemo(
        () => Dinero({ amount: chargeAmount, currency: chargeCurrency as Dinero.Currency }),
        [chargeCurrency, chargeAmount]
    );

    const createHold = async () => {
        console.info('Creating Hold...');
        if (!cartHold) {
            try {
                const fingerprint = await createFingerPrint();

                const payload: CreateHoldInput = {
                    fingerprint,
                    flexibleAmounts: formatFlexiblePricingForHold(cart?.flexiblePricing),
                    tickets: {
                        ...cart.quantities,
                        ...formatFlexiblePricingForTickets(cart?.flexiblePricing),
                    },
                    addons: cart.addons,
                    eventId: event!.id,
                    unlockCodes: inputtedUnlockCodes,
                    charged: {
                        amount: chargeDinero.getAmount(),
                        currency: chargeDinero.getCurrency(),
                    },
                    tosAcceptanceDate: new Date().toISOString(),
                };

                if (event?.checkoutCustomFields?.[0]?.id && checkoutNotes?.trim()) {
                    payload.customValues = {
                        [event.checkoutCustomFields[0].id]: checkoutNotes.trim(),
                    };
                }

                const { data } = await apollo.mutate({
                    mutation: CreateHoldDocument,
                    variables: {
                        payload,
                    },
                });

                if (!data?.createHold) {
                    throw Error('Hold creation failed');
                }

                setCart({
                    hold: {
                        id: data.createHold.id,
                        expiresAt: data.createHold.expiresAt,
                        visitorId: fingerprint,
                    },
                });

                return {
                    holdId: data.createHold.id,
                    visitorId: fingerprint,
                };
            } catch (err) {
                console.error(err);
                Sentry.captureException(err);

                return {
                    holdId: '',
                    visitorId: '',
                };
            }
        } else {
            return {
                holdId: cartHold.id as string,
                visitorId: cartHold.visitorId as string,
            };
        }
    };

    const deleteHold = async () => {
        console.info('Cancelling Hold...');
        const { hold } = getCart();

        if (hold?.id) {
            try {
                await apollo.mutate({
                    mutation: DeleteHoldDocument,
                    variables: {
                        deleteHoldId: hold.id,
                    },
                });
            } catch (err) {
                console.error(err);
            } finally {
                setCart({ hold: undefined });
            }
        }
    };

    const onPaymentAuthorized =
        (holdCreationPromise: Promise<{ holdId: string; visitorId: string }>) =>
        async (applePayResponse: ApplePayResponse, done: (ok: boolean) => void) => {
            try {
                setIsProcessing(true);
                const hold = await holdCreationPromise;

                if (!hold?.holdId || !hold?.visitorId) {
                    console.error('invalid hold');
                    done(false);
                    return;
                }

                if (!applePayResponse?.token?.transactionIdentifier) {
                    done(false);
                    return;
                }

                const sessionCreationResponse = await apollo.mutate({
                    mutation: CreateCheckoutSessionDocument,
                });

                if (!sessionCreationResponse?.data?.createCheckoutSession?.sessionToken) {
                    done(false);
                    return;
                }

                const variables: CreateOrderMutationVariables = {
                    payload: {
                        profile: {
                            firstName: applePayResponse.shippingContact.givenName,
                            lastName: applePayResponse.shippingContact.familyName,
                            email: applePayResponse.shippingContact.emailAddress,
                            phone: applePayResponse.shippingContact.phoneNumber,
                            streetAddress:
                                applePayResponse.shippingContact.addressLines?.join(', ') ?? '',
                            city: applePayResponse.shippingContact.locality ?? '',
                            province: applePayResponse.shippingContact.administrativeArea ?? '',
                            postalCode: applePayResponse.shippingContact.postalCode ?? '',
                            country: applePayResponse.shippingContact.countryCode ?? 'CA',
                        },
                        referral: cart?.referralCodes?.[0] ?? null,
                        paymentRequest: {
                            clientRequestId: uuidv4(),
                            sessionToken:
                                sessionCreationResponse.data.createCheckoutSession.sessionToken,
                            applePayToken: JSON.stringify(applePayResponse.token),
                        },
                        idempotencyKey: applePayResponse.token.transactionIdentifier,
                        hold: {
                            id: hold.holdId,
                            fingerprint: hold.visitorId,
                        },
                    },
                };

                const orderCreationResponse = await apollo.mutate({
                    mutation: CreateOrderDocument,
                    variables,
                });

                if (orderCreationResponse?.data?.createInternalOrder) {
                    setProcessedOrderId(orderCreationResponse.data.createInternalOrder);
                    setCart({ hold: undefined });

                    done(true);
                    history.push('/confirmation');
                } else {
                    done(false);
                }
            } catch (error) {
                console.error(error);

                if (error instanceof Error) {
                    setErrorAlert(error.message);
                }

                done(false);
            } finally {
                setIsProcessing(false);
            }
        };

    const onClick = useCallback(() => {
        if (isProcessing || !window?.sfc?.applePay) {
            return;
        }

        if (chargeDinero.getCurrency() !== 'CAD') {
            return;
        }

        const holdCreationPromise = createHold();

        const config = {
            collectUserDetails: true,
            countryCode: 'CA',
            currencyCode: chargeDinero.getCurrency(),
            supportedNetworks: ['visa', 'masterCard', 'amex'],
            requiredBillingContactFields: event?.requirePurchaserAddress
                ? ['name', 'postalAddress']
                : ['name'],
            requiredShippingContactFields: event?.requirePurchaserAddress
                ? ['name', 'phone', 'email', 'postalAddress']
                : ['name', 'email'],
            total: {
                label: 'AdmitONE',
                amount: chargeDinero.toUnit().toString(),
            },
            merchantSiteId: NUVEI_SITE_ID,
            env: NUVEI_ENV,
            shippingType: 'shipping',
        };

        const session = window.sfc.applePay.buildSession(
            config,
            onPaymentAuthorized(holdCreationPromise)
        );

        session.oncancel = deleteHold;
        session.begin();
    }, [onPaymentAuthorized, chargeDinero, createHold, deleteHold]);

    if (!isApplePayEnabled) {
        return null;
    }

    if (chargeDinero?.getCurrency() !== 'CAD') {
        // We only support CAD for ApplePay at this time
        return null;
    }

    if (chargeDinero.isZero()) {
        return null;
    }

    return (
        <Button
            style={{
                display: 'inline-block',
                // @ts-expect-error CSS directive recognized by Safari browser
                '-webkit-appearance': '-apple-pay-button',
                '-apple-pay-button-type': 'buy',
                height: 48,
            }}
            id="apple-pay-button"
            onClick={onClick}
        />
    );
};
