export const getIpAddress = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();

        return data.ip;
    } catch (err) {
        console.error(err);
        throw Error('Error getting IP Address');
    }
};
